<template>
  <div class="miya-primy-btn" ref="btnBg" :style="`opacity:${disabled?0.8:1};`" @click="clickBtn($event)" @mouseover.stop="hover=true" @mouseout="hover=false">
    <span>{{title}}</span>
    <div class="hoverBg" v-show="hover"></div>
    <div class="click" ref="clickBg"></div>
  </div>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
export default {
  data () {
    return {
      hover: false,
      isClick: false,
      disabled: false
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    clickBtn (e) {
      if (!this.isClick && !this.disabled) {
        this.isClick = true
        this.$nextTick(() => {
          let el = this.$refs.btnBg
          let target = this.$refs.clickBg
          let width = $(el).innerWidth()
          target.style.display = 'block'
          target.style.left = e.offsetX + 'px'
          target.style.top = e.offsetY + 'px'
          anime({
            targets: target,
            width: width * 2,
            height: width * 2,
            easing: 'linear',
            duration: 300,
          })
          this.$nextTick(() => {
            setTimeout(() => {
              target.style.width = 0
              target.style.height = 0
              target.style.display = 'none'
              this.$emit('click')
              this.isClick = false
            }, 310);
          })
        })
      }
    },
    disabledBtn () {
      this.disabled = true
    },
    enabledBtn () {
      this.disabled = false
    }
  },
  watch: {
    hover: {
      handler: function () {
        if (this.hover) {
          this.$emit('hover')
        }
      }
    }
  }
}
</script>
<style scoped lang="stylus">
.miya-primy-btn
  position relative
  overflow hidden
  cursor pointer
  .hoverBg
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    background-color rgba(255,255,255,0.04)
  .click
    position absolute
    width -40px
    left -40px
    border-radius 50%
    border 20px solid rgba(255,255,255,0.2)
    transform translate3d(-50%, -50%, 0)
</style>