<template>
  <div class="miya-login">
    <home-bg></home-bg>
    <div class="miya-login-header">
      <img class="logo" src="@assets/images/home/miyaLOGO.svg" @click.stop="$router.push('/home')">
      <div class="empty"></div>
      <div class="btnHome" @click="$router.push('/home')"><i class="iconfont icon-home"></i>{{$t('home.home')}}</div>
      <miya-lang></miya-lang>
    </div>
    <div class="pageCard">
      <div class="titleBox">
        <div class="tab active">{{$t('login.title1')}}</div>
        <div class="tab" @click="$router.push('/signup')">{{$t('login.title2')}}</div>
      </div>
      <div class="pageContent">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <p class="inputName">{{$t('login.username')}}</p>
          <el-form-item label="" prop="email" label-width="0">
            <div class="phoneEmail">
              <!-- <el-select v-model="prefix" class="prefix" :clearable="true" placeholder="">
                <el-option v-for="item in prefixList" :key="item" :label="item" :value="item"></el-option>
              </el-select> -->
              <el-input v-model="ruleForm.email" :placeholder="$t('login.placeholder3')" @keyup.enter.native="clickLogin()"></el-input>
            </div>
          </el-form-item> 
          <p class="inputName">{{$t('login.password')}}</p>
          <el-form-item label="" prop="password" label-width="0">
            <el-input show-password v-model="ruleForm.password" :placeholder="$t('login.placeholder3')"  @keyup.enter.native="clickLogin()"></el-input>
          </el-form-item>
        </el-form>
        <p class="forgetPassword"><span @click.stop="$router.push('/resetPassword')">{{$t('login.forgetPassword')}}</span></p>
        <div class="checkbox">
          <div class="radio" :class="{checked: checked}" @click="checked=!checked"></div><p class="checkText">{{$t('login.checkedText')}}<span class="click" @click="clickTos">{{$t('login.checkedText1')}}</span>{{$t('login.checkedText2')}}<span class="click" @click="clickTos2">{{$t('login.checkedText3')}}</span></p>
        </div>
        <div class="loginBtn" :class="{disabledBtn: disabledLogin}" @click.stop="clickLogin()">
          <div class="loading" v-loading="true" v-if="disabledLogin"></div>
          <div class="shadow"></div>
          <span>{{$t('login.login')}}</span>
        </div>
      </div>
    </div>
    <el-dialog :title="$t('login.sendCode')" :visible.sync="dialogCode" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false" width="420px">
      <div class="captcha-box">
        <div class="captcha">
          <el-input size="medium" v-model="captcha"></el-input>
        </div>
        <div class="vcImg">
          <img ref="vcImg" src="" @click="getCaptcha()">
        </div>          
      </div>    
      <div class="captcha-footer">
        <el-button class="cancel" type="info" size="medium" plain @click.stop="dialogCode = false">{{$t('login.cancel')}}</el-button>
        <el-button class="ok" type="info" size="medium" @click.stop="clickSendPhonecode()">{{$t('login.btnOK')}}</el-button>
      </div>
    </el-dialog>
    <miya-tos ref="miyaTos" @clickOK="checked=true"></miya-tos>
    <miya-tos2 ref="miyaTos2" @clickOK="checked=true"></miya-tos2>
  </div>
</template>
<script>
import miyaBtn from '@components/miyaBtn.vue'
import homeBg from '../home/homeBg.vue'
import miyaLang from '@components/lang.vue'
import miyaTos from './tos.vue'
import miyaTos2 from './tos2.vue'
import {setToken} from '@assets/js/http.js'
export default {
  data () {
    return {
      checked: false,
      ruleForm: {
        email: '',
        password: '',
      },
      rules: {
        email: [
          {required: true, message: this.$t('login.errorEmail'), trigger: 'blur'}
        ],
        password: [
          {required: true, message: this.$t('login.errorPassword'), trigger: 'blur'}
        ],
      },
      captcha: '',
      captchId: null,
      disabledLogin: false,
      dialogCode: false,
    }
  },
  created () {
    this.getUserInfo()
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.rules.email[0].message = this.$t('login.errorEmail')
      this.rules.password[0].message = this.$t('login.errorPassword')
    },
    getUserInfo () {
      this.$get('/Check/GetStatus').then(res => {
        if (res.code === 200) {
          this.$router.push('/layout')
        }
      })
    },
    clickLogin () {
      if (!this.checked) {
        this.$message.error(this.$t('login.error1'))
      } else {
        this.$refs.ruleForm.validate(v => {
          if (v) {
            this.login()
          }
        })
      }
    },
    login (account) {
      if (!this.disabledLogin) {
        this.disabledLogin = true
        let data = {
          account: this.ruleForm.email,
          password: this.ruleForm.password
        }
        this.$post(`/Check/Login`, data).then(res => {
          this.disabledLogin = false
          if (res.code === 500) {
            this.$message.error(this.$t('login.error2'))
          } else if (res.code === 200) {
            setToken(res.token)
            this.$store.commit('set_sessionId', res.token)
            this.$store.commit('set_isLoginGPT', true)
            this.$router.push('/layout')
          }
        })
      }
    },
    getCaptcha () {
      this.captchId = new Date().getTime()
      this.$refs.vcImg.src = `/api/Check/Captcha?id=${this.captchId}`
    },
    clickTos () {
      this.$refs.miyaTos.show()
    },
    clickTos2 () {
      this.$refs.miyaTos2.show()
    }
  },
  beforeDestroy () {
  },
  watch: {
    language () {
      this.getData()
    },
  },
  components: {miyaBtn, homeBg, miyaLang, miyaTos, miyaTos2}
}
</script>
<style scoped lang="stylus">
.miya-login
  min-height 100vh
  user-select none
  position relative
  .miya-login-header
    position absolute
    z-index 5
    top 0
    left 0
    width 100%
    padding 24px 32px
    display flex
    justify-content space-between
    .logo
      width 106px
    .empty
      flex 1
    .btnHome
      padding 0 20px
      color $c2
      font-size $f16
      background-color #F0F0F0
      line-height 36px
      border-radius 50px
      cursor pointer
      margin-right 36px
      .iconfont
        margin-right 6px
        font-size $f16
      &:hover
        color $c1
  .pageCard
    width 600px
    border-radius 20px
    border 1px solid #D0CFCE
    box-shadow 0 16px 28px rgba(20,17,14, 0.08)
    position relative
    top 140px
    margin 0 auto
    .titleBox
      border-radius 19px 19px 0 0
      background-color #FAFAFA
      line-height 70px
      color $c1
      padding 0 90px
      display flex
      border-bottom 1px solid #EBEBEB
      .tab
        font-size $f24
        font-weight 600
        margin-right 60px
        position relative
        cursor pointer
      .active
        color $c8
        font-size 28px
        &:before
          content ''
          background-color $c8
          position absolute
          width 100%
          height 2px
          background-color $c8
          left 0
          bottom -1px
    .pageContent
      border-radius 19px
      background-color $c7
      padding 16px 90px 32px 90px
      position relative
      .inputName
        font-size $f16
        color $c1
        padding 10px 0 10px 0
        font-weight 600
      >>>.el-input__inner
        border-radius 10px
        border 1px solid #D0CFCE
        background-color $c7
      .phoneEmail
        display flex
        .prefix
          width 160px
          margin-right 8px
      .checkbox
        margin-top 40px
        display flex
        .radio
          width 18px
          height 18px
          border-radius 50%
          border 1px solid #D0CFCE
          cursor pointer
          margin-top 2px
          &:hover
            border 1px solid $c8
        .checked
          border 1px solid $c8
          position relative
          &:before
            content ''
            display block
            position absolute
            top 0
            left 0
            bottom 0
            right 0
            margin auto
            width 10px
            height 10px
            border-radius 50%
            background-color $c8
            border 2px solid $c7
        .checkText
          flex 1
          font-size 16px
          color $c1
          line-height 20px
          margin-left 8px
          .click
            color $c8
            cursor pointer
      .tipString
        font-size $f14
        color $c2
        line-height 18px
        margin-top -16px
        margin-bottom 12px
      .sendCode
        position absolute
        top 0
        right 16px
        color $c8
        cursor pointer
      .password-box
        display flex
        .item
          flex 1
          &:last-child
            margin-left 24px
      .loginBtn
        line-height 48px
        text-align center
        letter-spacing 2px
        color $c7
        border-radius 60px
        font-size $f20
        margin 8px 0 20px 0
        width 100%
        position relative
        cursor pointer
        background: linear-gradient(88deg, #FFBF10 0%, #FF6F00 100%);
        &:hover
          background: linear-gradient(88deg, #FFCF20 0%, #FF7F10 100%);
        span
          position relative
          font-weight 600
          z-index 1
        .shadow
          position absolute
          width 90%
          height 90%
          left 50%
          top 14px
          background-color $c8
          opacity 0.5
          border-radius 50px
          filter: blur(20px) 
          transform translate3d(-50%,0,0)
          z-index 0
        .loading
          position absolute
          top 0
          left 0
          width 100%
          height 100%
          border-radius 50px
          overflow hidden
          >>>.el-loading-mask
            background-color rgba(255,255,255,0.3)
            backdrop-filter blur(3px)
          >>>.el-loading-spinner .circular
            width 28px
            height 28px
      .disabledBtn
        cursor not-allowed
      .account
        display flex
        justify-content center
        color $c2
        margin-left 0
      .forgetPassword
        color $c8
        text-align right
        cursor pointer
        margin-top -10px
        position relative
        z-index 5
  .captcha-box
    display flex
    margin-bottom 12px
    >>>.el-input__inner
      border-radius 10px
      border 1px solid #D0CFCE
      background-color $c7
    .captcha
      flex 1
    .vcImg
      width 150px
      margin-left 32px
      margin-top -5px
  .captcha-footer
    text-align center
    .cancel, .ok
      width 96px
      border-radius 50px
  >>>.el-dialog
    border-radius 20px
    .el-dialog__header
      font-size $f20
      font-weight 600
      padding 20px 0
      border-bottom 1px solid $c5
@media screen and (max-width: 800px) 
  .miya-login
    .miya-login-header
      padding 0.5rem 1rem
      .logo
        width 4rem
      .btnHome
        padding 0 1rem
        font-size 0.7rem
        line-height 1.6rem
        height 1.6rem
        .iconfont
          margin-right 0.3rem
          font-size 0.7rem
    .pageCard
      width 90%
      margin 0 auto
      top 5rem
      .titleBox
        padding 0 2rem
        line-height 3rem
        .tab
          font-size 1.1rem
      .pageContent
        padding 1rem 1.5rem
</style>